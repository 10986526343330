
import {directive} from 'vue-awesome-swiper';
import CoolLightBox from 'vue-cool-lightbox';
import BannerGridItemWrapper from '~/components/elements/BannerGridItemWrapper';
import {generateYoutubeVideoSourcePath} from '~/plugins/helpers/file';
import SliderArrows from '~/components/elements/SliderArrows';
import {findMaxNumber} from '~/plugins/helpers/catalog';

export default {
  name: 'YoutubeSlider',
  components: {
    SliderArrows,
    BannerGridItemWrapper,
    CoolLightBox,
  },
  directives: {
    swiper: directive,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    titleCenter: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      required: true,
    },
    sliderClass: {
      type: String,
      required: true,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    ratio: {
      type: Object,
      required: true,
    },
    round: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    breakpoints: {
      type: Object,
      default() {
        return {}
      },
    },
    pagination: {
      type: String,
      default: 'none',
    },
    navigation: {
      type: String,
      default: 'header',
    },
    borderRadius: {
      type: String,
      default: '.4rem',
    },
  },
  data() {
    const defaultBreakpoints = {
      // when window width is >= 320px
      320: {
        spaceBetween: 0,
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      // when window width is >= 768px
      575: {
        spaceBetween: 12,
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      768: {
        spaceBetween: 12,
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      // when window width is >= 992px
      992: {
        spaceBetween: 24,
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      1200: {
        spaceBetween: 24,
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
    }

    let breakpoints = {}

    if (Object.keys(this.breakpoints).length) {
      breakpoints = this.breakpoints
    } else {
      breakpoints = defaultBreakpoints
    }

    let pagination = {}
    let navigation = {}

    if (this.pagination !== 'none') {
      const el = `.${this.sliderClass} .${this.pagination === 'fraction'
        ? 'product-slider__controls-fraction'
        : 'product-slider__dotts'}`

      pagination = { el, clickable: true, type: this.pagination }
    }

    if (this.navigation !== 'none') {
      navigation = {
        nextEl: `.${this.sliderClass} .product-slider__arrow-next`,
        prevEl: `.${this.sliderClass} .product-slider__arrow-prev`,
      }
    }

    function getMinSlides() {
      const keys = Object.keys(breakpoints).map(key => +key)

      const maxKey = findMaxNumber(keys) || 0

      if (!maxKey) return 0

      return +breakpoints[maxKey].slidesPerView
    }

    return {
      imageOpenIndex: null,
      icons: {
        carrot: 'arrow-small',
      },
      minSlides: getMinSlides(),
      sliderOptions: {
        observer: true,
        observeParents: true,
        spaceBetween: 24,
        centeredSlides: false,
        freeMode: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        loop: this.loop,
        preloadImages: false,
        pagination,
        navigation,
        breakpoints,
      },
    }
  },
  computed: {
    galleryItems() {
      const slides = []
      this.images.forEach(item => {
        if (item?.youtube) {
          slides.push({ src: generateYoutubeVideoSourcePath(item.youtube), youtube: item.youtube  })
        }
      })

      return slides
    },
    sliderStyle() {
      const styles = {}

      Object.keys(this.sliderOptions.breakpoints).forEach(key => {
        const item = this.sliderOptions.breakpoints[key]

        const countedSlides = item.slidesPerView > 1 ? item.slidesPerView - 1 : item.slidesPerView
        const totalMargin = countedSlides * item.spaceBetween
        const maxPercent = 100 - (totalMargin / +key * 100)

        styles[`--slider-margin-${key}`] = `${item.spaceBetween}px`
        styles[`--slider-view-${key}`] = `${maxPercent / item.slidesPerView}%`
      })

      return styles
    },
    isSliderPagination() {
      return this.pagination !== 'none'
    },
    isNavigationHeader() {
      return this.navigation === 'header' && this.images?.length > this.minSlides
    },
    isNavigationBlock() {
      return this.navigation === 'block' && this.images?.length > this.minSlides
    },
  },
  watch: {
    '$mq'() {
      this.updateMinSlides()
    },
  },
  beforeMount() {
    this.updateMinSlides()
  },
  methods: {
    updateMinSlides() {
      const curBreakpoint = this.getCurrentBreakpoint()

      if (curBreakpoint) {
        this.minSlides = +curBreakpoint.slidesPerView
      }
    },
    getCurrentBreakpoint() {
      switch (this.$mq) {
        case 'mobileXs':
          return this.sliderOptions.breakpoints['320']
        case 'mobile':
          return this.sliderOptions.breakpoints['575']
        case 'tablet':
          return this.sliderOptions.breakpoints['768']
        default:
          return this.sliderOptions.breakpoints['1200']
      }
    },
    onClickBanner(item) {
      const { youtube } = item

      if (!youtube) return

      const id = this.getCurrentGalleryId(youtube)

      if (id !== -1) {
        this.imageOpenIndex = id
      }
    },
    getCurrentGalleryId(youtube) {
      return this.galleryItems.findIndex(item => item.youtube === youtube)
    },

  },
}
